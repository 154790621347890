import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { ToggleTheme, BottomTabs } from "../../components";
import {
  Container,
  IconButton,
  Input,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import {
  logo,
  Rectangle3,
  Rectangle4,
  Rectangle5,
  Rectangle6,
  Rectangle7,
  Rectangle8,
  Rectangle9,
  Rectangle10,
  Rectangle11,
} from "../../assets";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import useWindowDimensions from "../../hooks/window-dimensions";

export default function Videos() {
  const { height, width } = useWindowDimensions();

  const ht = {
    logoSec: 120,
    searchSec: 60,
    tabs: 80,
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: "custom.main",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "120px",
            backgroundColor: "custom.main",
            position: "relative",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={logo} alt="" className="img-fluid" />
            <Typography
              component="span"
              varient="h5"
              sx={{
                position: "absolute",
                color: "custom.switch",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Videos
            </Typography>
          </div>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <ToggleTheme />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "60px",
            alignItems: "center",
            display: "flex",
            backgroundColor: "custom.main",
          }}
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
            }}
          >
            <Input
              id="input-with-icon-adornment"
              placeholder="Search for news"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon
                      sx={{
                        color: "custom.switch",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                backgroundColor: "custom.md",
                borderRadius: "35px",
                borderWidth: 2,
                borderColor: "#7A7A7A",
                borderStyle: "solid",
                padding: "10px 15px",
                color: "custom.switch",
                "& ::placeholder": {
                  color: "custom.switch",
                  opacity: 1,
                },
                "&&&:before": {
                  borderBottom: "none",
                },
                "&&:after": {
                  borderBottom: "none",
                },
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `${
              height - (ht.logoSec + ht.searchSec + ht.tabs)
            }px`,
            overflow: "auto",
          }}
        >
        </Box>
      </Container>
      <BottomTabs />
    </>
  );
}

const Card = ({ item }) => {
  return (
    <Box
      sx={{
        width: "calc(100% / 3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button onClick={() => console.log("press")} fullWidth={true}>
        <div style={{ position: "relative", width: "100%" }}>
          <img src={item.src} width="100%" className="img-fluid" />
          <Typography
            sx={{
              position: "absolute",
              top: '50%',
              left: '50%',
              transform: "translate(-50%,-50%)",
              color: "custom.light",
            }}
          >
            {item.label}
          </Typography>
        </div>
      </Button>
    </Box>
  );
};

const Data = [
  {
    id: 1,
    src: Rectangle3,
    label: "Covid-19",
  },
  {
    id: 2,
    src: Rectangle4,
    label: "India",
  },
  {
    id: 3,
    src: Rectangle5,
    label: "Startup",
  },
  {
    id: 4,
    src: Rectangle6,
    label: "Politics",
  },
  {
    id: 5,
    src: Rectangle7,
    label: "Sports",
  },
  {
    id: 11,
    src: Rectangle8,
    label: "Covid-19",
  },
  {
    id: 22,
    src: Rectangle9,
    label: "India",
  },
  {
    id: 33,
    src: Rectangle10,
    label: "Startup",
  },
  {
    id: 44,
    src: Rectangle11,
    label: "Politics",
  },
  {
    id: 55,
    src: Rectangle3,
    label: "Sports",
  },
  {
    id: 55,
    src: Rectangle4,
    label: "Sports",
  },
  {
    id: 55,
    src: Rectangle5,
    label: "Sports",
  },
  {
    id: 55,
    src: Rectangle3,
    label: "Sports",
  },
  {
    id: 55,
    src: Rectangle4,
    label: "Sports",
  },
  {
    id: 55,
    src: Rectangle5,
    label: "Sports",
  },
];
