import { IconButton } from '@mui/material';
import React, { useContext } from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { MODE } from '../../context/mode';

export default function ToggleTheme() {
    const { setMode, mode } = React.useContext(MODE);
    return (
        <div>
            <IconButton
                onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
                sx={{
                    backgroundColor: mode === 'light' ? '#FFFFFF' : '#EFEFEF'
                }}
            >
                {mode === 'light' ?
                    <LightModeIcon sx={{ color: '#E1C010' }} />
                    :
                    <ModeNightIcon sx={{ color: '#007B96' }} />
                }
            </IconButton>
        </div>
    );
}