import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { ToggleTheme } from "../../components";
import {
  Container,
  IconButton,
  Input,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { successPng, Rectangle13 } from "../../assets";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import useWindowDimensions from "../../hooks/window-dimensions";
import { useNavigate } from "react-router-dom";
import { MODE } from "../../context/mode";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import HelpIcon from "@mui/icons-material/Help";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import ShareIcon from "@mui/icons-material/Share";
import ChatIcon from "@mui/icons-material/Chat";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from "@mui/icons-material/Forum";
import ReplyIcon from "@mui/icons-material/Reply";
import { RWebShare } from "react-web-share";

const slidesData = ['red', 'green', 'blue'];
export default function News() {
  const { setMode, mode } = React.useContext(MODE);
  const [activeTab, setActiveTab] = useState("All_News");
  const [headerView, setHeaderView] = useState(false);
  const [btnGroup, setBtnGroup] = useState(false);
  const [OpenSearch, setOpenSearch] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const { height, width } = useWindowDimensions();

  const [touchXStart, setTouchXStart] = React.useState(0);
  const [touchXEnd, setTouchXEnd] = React.useState(0);
  const [touchYStart, setTouchYStart] = React.useState(0);
  const [touchYEnd, setTouchYEnd] = React.useState(0);

  const [activeSlide, setActiveSlide] = React.useState(slidesData.length);
  const [prevSlides, setPrevSlides] = React.useState([]);

  const navigate = useNavigate();

  const handleClickAway = () => {
    setOpenSearch(false);
  };

  const handleTouchStart = (e) => {
    setTouchXStart(e.targetTouches[0].clientX);
    setTouchYStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchXEnd(e.targetTouches[0].clientX);
    setTouchYEnd(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    let thresold = 150;
    if (touchXStart - touchXEnd > thresold && touchXEnd !== 0) {
      console.log("swipe left");
      window.open('https://www.google.com/', '_blank', 'noopener,noreferrer');
    }

    if (touchXStart - touchXEnd < -thresold && touchXEnd !== 0) {
      console.log("swipe right");
      navigate("/");
    }

    if (touchYStart - touchYEnd > thresold && touchYEnd !== 0) {
      console.log("swipe Top");
      if (prevSlides.length < slidesData.length - 1) {
        let arr = prevSlides;
        arr.push(activeSlide);
        setActiveSlide(activeSlide - 1);
        setPrevSlides(arr);
      }
    }

    if (touchYStart - touchYEnd < -thresold && touchYEnd !== 0) {
      console.log("swipe Bottom");
      if (prevSlides.length) {
        let arr = prevSlides;
        arr.pop();
        setActiveSlide(activeSlide + 1);
        setPrevSlides(arr);
      }
    }
    setTouchXEnd(0);
    setTouchYEnd(0);
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundColor: "custom.main",
          position: "relative",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "relative" }}>
          <Box
            sx={{
              width: "100%",
              height: "100px",
              backgroundColor: "custom.main",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: headerView ? "0" : "-150px",
              left: 0,
              padding: "0 15px",
              zIndex: 100,
              transition: "0.5s all ease-in-out",
            }}
          >
            {!OpenSearch && (
              <Box sx={{ flex: 1 }}>
                <Button
                  onClick={() => setActiveTab("All_News")}
                  sx={{
                    textTransform: "capitalize",
                    color:
                      activeTab === "All_News" ? "custom.switch" : "#6A6A6A",
                    padding: 0,
                  }}
                >
                  All News
                </Button>
                <Button
                  onClick={() => setActiveTab("Top_Stories")}
                  sx={{
                    textTransform: "capitalize",
                    color:
                      activeTab === "Top_Stories" ? "custom.switch" : "#6A6A6A",
                  }}
                >
                  Top Stories
                </Button>
                <Button
                  onClick={() => setActiveTab("Trending")}
                  sx={{
                    textTransform: "capitalize",
                    color:
                      activeTab === "Trending" ? "custom.switch" : "#6A6A6A",
                  }}
                >
                  Trending
                </Button>
              </Box>
            )}
            {OpenSearch && (
              <Box
                sx={{
                  width: "100%",
                  flex: 1,
                }}
                component="form"
                noValidate
                autoComplete="off"
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Input
                      id="input-with-icon-adornment"
                      placeholder="Search for news"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon
                              onClick={() => console.log("press")}
                              sx={{
                                color: "custom.switch",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        backgroundColor: "custom.md",
                        borderRadius: "35px",
                        borderWidth: 2,
                        borderColor: "#7A7A7A",
                        borderStyle: "solid",
                        padding: "10px 15px",
                        color: "custom.switch",
                        "& ::placeholder": {
                          color: "custom.switch",
                          opacity: 1,
                        },
                        "&&&:before": {
                          borderBottom: "none",
                        },
                        "&&:after": {
                          borderBottom: "none",
                        },
                      }}
                    />
                  </FormControl>
                </ClickAwayListener>
              </Box>
            )}
            {!OpenSearch && (
              <Box ml={1}>
                <IconButton
                  onClick={() => setOpenSearch(true)}
                  sx={{
                    backgroundColor:
                      mode === "dark" ? "#EFEFEF" : "rgba(255,255,255,0.2)",
                  }}
                >
                  <SearchIcon sx={{ color: "custom.switch" }} />
                </IconButton>
              </Box>
            )}
            <Box ml={1}>
              <ToggleTheme />
            </Box>
          </Box>
        </div>
        <Box
          component="div"
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
          onClick={() => {
            setHeaderView(!headerView);
            setBtnGroup(!btnGroup);
          }}
        >
          {slidesData.map((v, i) => {
            return <PostItem
              key={i}
              item={v}
              index={i + 1}
              prevSlides={prevSlides}
              activeSlide={activeSlide}
              height={height}
            />
          })}
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "60px",
            backgroundColor: "custom.main",
            alignItems: "center",
            display: "flex",
            position: "absolute",
            bottom: btnGroup ? "0" : "-150px",
            left: 0,
            padding: "0 15px",
            zIndex: 5,
            transition: "0.5s all ease-in-out",
          }}
        >
          <Stack
            spacing={0}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpenCommentModal(true)}
              startIcon={<ChatIcon sx={{ color: "custom.switch" }} />}
              sx={{
                color: "custom.switch",
                textTransform: "capitalize",
                fontSize: "12px",
                borderColor: "custom.switch",
                borderRadius: 25,
              }}
            >
              Add a comment
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenViewModal(true)}
              startIcon={<ForumIcon sx={{ color: "custom.switch" }} />}
              sx={{
                color: "custom.switch",
                textTransform: "capitalize",
                borderColor: "custom.switch",
                fontSize: "12px",
                borderRadius: "25px",
              }}
            >
              Comments
            </Button>
            <RWebShare
              data={{
                text: "Like humans, flamingos make friends for life",
                url: "https://on.natgeo.com/2zHaNup",
                title: "Flamingos",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <IconButton
                sx={{
                  backgroundColor: "custom.switch",
                }}
              >
                <ShareIcon sx={{ color: "custom.main" }} />
              </IconButton>
            </RWebShare>
          </Stack>
        </Box>
        {/* Add Comment Modal */}
        <Modal
          open={openCommentModal}
          onClose={() => setOpenCommentModal(!openCommentModal)}
          aria-labelledby="Add Comment"
          aria-describedby="Add-Comment-Modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "400px",
              p: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "custom.main",
                px: 2,
                pb: 4,
                pt: 1,
                borderRadius: "7px",
              }}
            >
              <div style={{ textAlign: "end" }}>
                <IconButton
                  onClick={() => setOpenCommentModal(!openCommentModal)}
                >
                  <CloseIcon sx={{ color: "custom.switch" }} />
                </IconButton>
              </div>
              {showMessage ? (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ color: "custom.switch" }}
                  >
                    Comment Added
                  </Typography>
                  <img src={successPng} className="img-fluid" alt="" />
                </Box>
              ) : (
                <>
                  <FormControl
                    variant="standard"
                    sx={{
                      mt: 1,
                      mb: 2,
                      width: "100%",
                    }}
                  >
                    <Input placeholder="Add your comment" sx={inputStyle} />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Input
                      placeholder="Add your comment"
                      sx={inputStyle}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                  <Button
                    sx={{
                      backgroundColor: "custom.switch",
                      color: "custom.main",
                      textTransform: "capitalize",
                      mt: 2,
                      display: "block",
                      ml: "auto",
                    }}
                    onClick={() => setShowMessage(true)}
                  >
                    Add Comment
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Modal>
        {/* View Comment Modal */}
        <Modal
          open={openViewModal}
          onClose={() => setOpenViewModal(!openViewModal)}
          aria-labelledby="View Comments"
          aria-describedby="View-Comments-Modal"
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              transition: "0.5s all ease-in-out",
              p: 2,
              backgroundColor: "custom.main",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <Stack
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: "custom.switch" }}
              >
                Comments
              </Typography>
              <IconButton onClick={() => setOpenViewModal(!openViewModal)}>
                <CloseIcon sx={{ color: "custom.switch" }} />
              </IconButton>
            </Stack>
            <Box
              component="div"
              sx={{
                maxHeight: "350px",
                overflowY: "auto",
              }}
            >
              <CommentItem mode={mode} />
              <CommentItem mode={mode} />
              <CommentItem mode={mode} />
              <CommentItem mode={mode} />
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

const shareContainer = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 30px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
};

const inputStyle = {
  backgroundColor: "custom.md",
  borderRadius: "7px",
  borderWidth: 1,
  borderColor: "custom.switch",
  borderStyle: "solid",
  padding: "10px 15px",
  color: "custom.switch",
  "& ::placeholder": {
    color: "custom.switch",
    opacity: 1,
  },
  "&&&:before": {
    borderBottom: "none",
  },
  "&&:after": {
    borderBottom: "none",
  },
};

const CommentItem = ({ mode }) => {
  return (
    <Box
      component="div"
      sx={{
        border: "1px solid",
        borderColor: "custom.switch",
        borderRadius: "7px",
        p: 1,
        mb: 1,
      }}
    >
      <Stack flexDirection="row">
        <Typography
          variant="p"
          component="p"
          sx={{ color: mode === "dark" ? "#334253" : "#334253", mr: 2 }}
        >
          amyrobson
        </Typography>
        <Typography variant="p" component="p" sx={{ color: "#67727E" }}>
          1 month ago
        </Typography>
      </Stack>
      <Typography
        variant="p"
        component="p"
        sx={{ color: "#67727E", mt: 2 }}
        mb={2}
      >
        After President Joe Biden said the coronavirus pandemic was "over" in an
        interview Sunday, many people.
      </Typography>
      <Stack alignItems="flex-end">
        <Button
          variant="text"
          onClick={() => console.log("press")}
          startIcon={<ReplyIcon sx={{ color: "custom.switch" }} />}
          sx={{
            color: "custom.switch",
            backgroundColor: "transparent",
            textTransform: "capitalize",
            borderColor: "custom.switch",
          }}
        >
          Replay
        </Button>
      </Stack>
    </Box>
  );
};

const PostItem = ({ index, item, prevSlides, height }) => {
  return (
    <Box
      sx={{
        height: "100vh",
        position: "absolute",
        backgroundColor: "custom.main",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: index,
        transition: '0.6s all ease-in-out',
        transform: prevSlides.findIndex(x => x == index) !== -1 ? `translateY(-${height + 200}px)` : "translateY(0)"
      }}
    >
      <Box component="div" sx={{ position: "relative" }}>
        <img src={Rectangle13} width="100%" className="img-fluid" alt="" />
        <Box
          component="div"
          sx={{
            backgroundColor: "custom.main",
            position: "absolute",
            right: 0,
            bottom: 4,
            backgroundColor: "rgba(0,0,0,0.4)",
            borderTopLeftRadius: 10,
          }}
        >
          <IconButton
            onClick={() => console.log('there')}
          >
            <HelpIcon sx={{ color: "custom.light" }} />
          </IconButton>
          <IconButton
            onClick={() => console.log('there')}
          >
            <TurnedInIcon sx={{ color: "custom.light" }} />
          </IconButton>
        </Box>
      </Box>
      <Container maxWidth={false} sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Typography
          variant="h6"
          component="h4"
          sx={{ color: "custom.switch" }}
          mb={2}
        >
          Covid will be a leading cause of death in the U.S. indefinitely,
          whether or not the pandemic is 'over'
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{ color: "custom.switch" }}
          mb={2}
        >
          A health care worker treats a Covid-19 patient on an intensive care
          unit in Hartford, Conn., on Feb. 1.Allison Dinner / Bloomberg via
          Getty Images file
        </Typography>
        <Typography variant="p" component="p" sx={{ color: "#CACACA" }} mb={2}>
          After President Joe Biden said the coronavirus pandemic was "over" in
          an interview Sunday, many people were left wondering how to reconcile
          his comment with the fact that the U.S. is still averaging about 500
          Covid deaths every day.
          <br />
          <br />
          But disease experts said debating whether the pandemic is over
          overshadows a more important concern: the reality that Covid will
          remain a leading cause of death in the U.S. indefinitely.
        </Typography>
      </Container>
    </Box>
  );
};
