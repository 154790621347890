import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ArticleIcon from '@mui/icons-material/Article';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Link, useLocation } from 'react-router-dom';

export default function BottomTabs() {
  const location = useLocation();
  const route = location.pathname;
  const active = getPageIndex(route);

  React.useEffect(() => {
    console.log("🚀 ~ props", route)
  }, []);

  function getPageIndex(route) {
    switch (route) {
      case '/': return 0;
      case '/videos': return 1;
      case '/news': return 2;
      case '/info-picha': return 3;
      default: return 0;
    }
  }

  return (
    <Box sx={{ width: '100%', position: 'fixed', bottom: 0, left: 0 }}>
      <BottomNavigation
        showLabels
        value={active}
        sx={{
          backgroundColor: 'custom.main',
          height: "80px",
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          "& svg": {
            marginBottom: "10px"
          },
          "& .MuiBottomNavigationAction-root, svg": {
            color: "#969696"
          },
          "& .Mui-selected,.Mui-selected svg": {
            color: "custom.switch"
          }
        }}
      >
        <BottomNavigationAction
          component={Link}
          to="/"
          label="Bulletins"
          icon={<RemoveRedEyeIcon />} />
        <BottomNavigationAction
          component={Link}
          to="/videos"
          label="Videos"
          icon={<OndemandVideoIcon />} />
        <BottomNavigationAction
          component={Link}
          to="/news"
          label="News"
          icon={<ArticleIcon />} />
        <BottomNavigationAction
          component={Link}
          to="/info-picha"
          label="InfoPicha"
          icon={<NewReleasesIcon />} />
      </BottomNavigation>
    </Box>
  );
}
