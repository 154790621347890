import React from "react";
import { Routes, Route } from "react-router-dom";
import { Bulletins, Videos, News, InfoPicha } from "../screens";
const myRoutes = [
    {
        path: "/",
        component: <Bulletins />,
    },
    {
        path: "/videos",
        component: <Videos />,
    },
    {
        path: "/news",
        component: <News />,
    },
    {
        path: "/info-picha",
        component: <InfoPicha />,
    },
];

export default function Router() {

    return (
        <Routes>
            {myRoutes.map((Item) => {
                return (
                    <Route
                        key={Math.random() * 100}
                        path={Item.path}
                        element={Item.component}
                    />
                );
            })}
        </Routes>
    );
}