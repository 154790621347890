import React, { useState, useEffect } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import Router from './router/router';
import { MODE } from './context/mode';

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

export default function App() {
  const [device, setDevice] = useState(null);
  const [mode, setMode] = useState('dark');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
              // palette values for light mode
              custom: {
                main: '#000000',
                Dark: '#000000',
                light: '#FFFFFF',
                switch: '#FFFFFF',
                textDark: '#000000',
                textLight: '#FFFFFF',
                md: '#212121',
              }
            }
            : {
              // palette values for dark mode
              custom: {
                main: '#FFFFFF',
                switch: '#000000',
                textDark: '#000000',
                textLight: '#FFFFFF',
                Dark: '#000000',
                light: '#FFFFFF',
                md: '#FFFFFF',
              }
            }),
        },
        typography: {
          fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
      }),
    [mode],
  );

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDevice('Mobile');
    } else {
      setDevice('Desktop');
    }
  }, []);


  const shareContainer = {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  };

  return (
    <>
      {device === 'Mobile' ?
        <MODE.Provider value={{ mode, setMode }}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </MODE.Provider>
        :
        <small>Mobile screen only</small>
      }
    </>
  );
}



